import { render, staticRenderFns } from "./CouponRemoveModal.vue?vue&type=template&id=70b156f9&scoped=true&lang=html"
import script from "./CouponRemoveModal.vue?vue&type=script&lang=js"
export * from "./CouponRemoveModal.vue?vue&type=script&lang=js"
import style0 from "./CouponRemoveModal.vue?vue&type=style&index=0&id=70b156f9&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b156f9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70b156f9')) {
      api.createRecord('70b156f9', component.options)
    } else {
      api.reload('70b156f9', component.options)
    }
    module.hot.accept("./CouponRemoveModal.vue?vue&type=template&id=70b156f9&scoped=true&lang=html", function () {
      api.rerender('70b156f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/checkoutOnePage/Coupon/CouponRemoveModal.vue"
export default component.exports