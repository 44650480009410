<template>
    <div class="accountHistory">
        <div class="accountHistory__list">
            <div class="accountHistory__item" v-for="order in leanOrders" :key="order.orderNumber">
                <OrderCard :orderInformation="order" />
            </div>
        </div>

        <div class="accountHistory__wrapButton text-center mb-3">
            <ButtonVue 
                v-if="pagination?.totalPages > pagination?.page && pagination?.page <= pagination?.totalPages"
                label="Ver mais" 
                class="sm" 
                @click="loadMore"
                :class="isLoading ? 'is__loading' : ''"
            />
        </div>

    </div>
</template>

<script>

import routes from '../api/routes';
import OrderCard from '../components/Account/OrderCard.vue';
import ButtonVue from '../components/ui/ButtonVue.vue';

export default {
    props: {
        orders: {
            type: Object
        }
    },
    components: {
        OrderCard,
        ButtonVue       
    },
    data() {
        return {
            isLoading: false,
            pagination: this.orders?.pagination || {},
            leanOrders: this.orders?.orders || []
        };
    },  
    computed: {
        nextPage() {
            return (this.orders?.pagination?.page && this.orders?.pagination?.pageSize <= this.orders?.pagination?.page) ? this.orders?.pagination?.page + 1 : this.orders?.pagination?.page
        }
    },
    methods: {
        async loadMore(){
            this.isLoading = true;

            try {
                
                const response = await fetch(`${routes.urlSite}Order-HistoryPagination?page=${this.pagination.page + 1}&pageSize=${this.pagination.pageSize}`);
                const data = await response.json();
    
                this.isLoading = false;
    
                if(data.success){
                    this.leanOrders = [...this.leanOrders, ...data.leanOrders];
                    this.pagination =  data.pagination;
                }
            } catch (error) {
                this.isLoading = false;                
                console.warn('Error: Load more orders', error);
            }
        }
    }
};
</script>