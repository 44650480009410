import { render, staticRenderFns } from "./AccordionItem.vue?vue&type=template&id=6623ea1f"
import script from "./AccordionItem.vue?vue&type=script&lang=js"
export * from "./AccordionItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6623ea1f')) {
      api.createRecord('6623ea1f', component.options)
    } else {
      api.reload('6623ea1f', component.options)
    }
    module.hot.accept("./AccordionItem.vue?vue&type=template&id=6623ea1f", function () {
      api.rerender('6623ea1f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/ui/AccordionItem.vue"
export default component.exports