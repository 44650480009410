
<template>
    <div>
        <div class="row insurance-wrapper mx-0 mx-lg-3">
            <div class="mob-text--small col-12 col-lg-8 px-0">
                <div class="insurance-info mb-4">
                    <b> {{ resources.insurance.text }} </b>
                    <span id="viewInsurance" class="insuranceLink" @click="openModal">
                        {{ resources.payment.insurance.modalTitle }}
                    </span>
                </div>
                <checkbox-vue
                    id="insurance--checkbox"
                    :label="resources.insurance.cta"
                    :checked="!!activeInsurance"
                    v-model="activeInsurance"
                    @change="toggleInsurance"
                />
            </div>
            <p class="insurance-price-box col-12 col-lg-4 mb-0">
                {{ resources.insurance.price }}
                <span class="insurance-price"> {{ insurance }}</span>
            </p>
            <insurance-modal />
        </div>
    </div>
</template>

<script>
    import CheckboxVue from '../../ui/CheckboxVue.vue';
    import InsuranceModal from './insuranceModal.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        components: {
            'insurance-modal': InsuranceModal,
            'checkbox-vue': CheckboxVue,
        },
        computed: {
            ...mapGetters('checkoutOnePage', {
                activeInsuranceValue: 'activeInsurance',
                totals: 'totals',
                resources: 'resources',
            }),
            activeInsurance: {
                get() {
                    return !!this.activeInsuranceValue;
                },
                set(value) {
                    this.updateActiveInsurance(!!value);
                },
            },
            insurance() {
                return this.totals.insurance ? this.totals.insurance.formatted : 'R$ 0,00';
            },
        },
        methods: {
            ...mapActions('checkoutOnePage', ['updateActiveInsurance']),
            toggleInsurance() {
                this.activeInsurance = !this.activeInsurance;
            },
            openModal() {
                $('#insuranceModal').modal('show');
            },
        },
        mounted() {
            this.activeInsurance = false;
        }
    };
</script>

<style scoped lang="scss">
    .insurance-wrapper {
        display: flex;
        padding: 18px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: .625rem;
        border: 1px solid #55368b;
        background: rgba(85, 54, 139, .1);
        margin: 0 20px;
    }

    .insurance-info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: left;

        b {
            color: #000;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.175rem;
        }

        .insuranceLink {
            color: #55368B;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;
            text-decoration-line: underline;
        }
    }

    .insurance-price-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: #55368B;
        text-align: center;
        font-size: 1.25rem;
        font-style: italic;
        font-weight: 600;
        line-height: normal;

        .insurance-price{
            color: #55368B;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
</style>
