<template>
    <div class="personal-data-form">
        <div class="d-flex justify-content-center purple--messageButton">
            <p class="mob-text--large">
                Já tem uma conta Mobifácil?
            </p>
            <ButtonVue label="Entrar" className="ghost h-100 mob-text--large mob-color--primary"
                @click="openLoginModal" :attributes="[
                { name: 'orange-id', value: 'checkoutLogoutButton' },
            ]" />
            <LoginModal :hideRegister="true" />
            <RegisterModal />
        </div>

        <h4 class="mob-text--section-title bd my-3">Ou insira seus dados para continuar com a compra.</h4>

        <h4 class="mob-subtitle--small mob-color--secondary bd mb-3">Dados Pessoais</h4>

        <InputVue
            class="form-group"
            id="personal-form-email"
            name="personal-form-email"
            type="email"
            :placeholder="resources.forms.email"
            :attributes="[
                { name: 'orange-id', value: 'personal-form-email' },
                { name: 'autocomplete', value: 'off'}
            ]"
            :class="{
                disabled: $root.$refs.Identification.completedEmail
            }"
            v-model="$root.$refs.Identification.customerData.email"
            :errorMessage="$root.$refs.Identification.erros.email"
        />

        <div class="form-row">
            <InputVue
                class="form-group col-md-6"
                id="personal-form-name"
                name="personal-form-name"
                className="personal-form-name"
                :placeholder="resources.forms.fullName"
                :attributes="[{ name: 'orange-id', value: 'personal-form-name' }]"
                v-model="$root.$refs.Identification.customerData.name"
                :errorMessage="$root.$refs.Identification.erros.name"
            />
            <InputVue
                class="form-group col-md-6"
                id="personal-form-cellphone"
                name="personal-form-cellphone"
                useMask="phone"
                :placeholder="resources.forms.phoneWithDDD"
                :attributes="[
                    { name: 'orange-id', value: 'personal-form-cellphone' },
                    { name: 'minlength', value: 11 },
                    { name: 'autocomplete', value: 'tel' },
                ]"
                v-model="$root.$refs.Identification.customerData.phone"
                :errorMessage="$root.$refs.Identification.erros.phone"
            />
        </div>
        <div class="form-row">
            <SelectVue
                class="form-group col-md-6"
                id="personal-form-documentType"
                name="personal-form-documentType"
                :attributes="[
                    { name: 'orange-id', value: 'personal-form-documentType'},
                ]"
                :options="documentTypeOptions"
                v-model="$root.$refs.Identification.customerData.documentType"
                :errorMessage="$root.$refs.Identification.erros.documentType"
            />
            <InputVue
                class="form-group col-md-6"
                id="personal-form-documentNumber"
                name="personal-form-documentNumber"
                :placeholder="resources.forms.documentNumber"
                :attributes="[{ name: 'orange-id', value: 'personal-form-documentNumber' }]"
                :useMask="$root.$refs.Identification.customerData.documentType.toLowerCase()"
                v-model="$root.$refs.Identification.customerData.documentNumber"
                :errorMessage="$root.$refs.Identification.erros.documentNumber"
            />
        </div>
    </div>
</template>

<script>
import InputVue from '../../ui/InputVue.vue'
import SelectVue from '../../ui/SelectVue.vue'
import { mapGetters } from 'vuex'
import LoginModal from '../../Account/LoginModal.vue';
import RegisterModal from '../../Account/RegisterModal.vue';
import ButtonVue from '../../ui/ButtonVue.vue'

export default {
    components: {
        InputVue,
        SelectVue,
        LoginModal,
        ButtonVue,
        RegisterModal,
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            resources: 'resources',
        }),
        documentTypeOptions() {
            return [
                { value: 'cpf', label: 'CPF' },
                { value: 'passport', label: this.resources.forms.passport },
                { value: 'rg', label: 'RG' },
            ]
        },
    },
    methods: {
        openLoginModal() {
            $('#login-modal').modal('show');
        },
        showForgetPasswordModal() {
            $(`#forgetPasswordModal`).modal('show');
        },

    },
    created() {
        this.guestEmail = this.$store.state.account.email;
    },
    mounted() {
        $('#personal-form-email, #personal-form-name').on('blur', async () => {
            const email = this.$root.$refs.Identification.customerData.email.trim();
            const name = this.$root.$refs.Identification.customerData.name.trim();
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            this.$root.$refs.Identification.erros.email = '';
            this.$root.$refs.Identification.erros.name = '';

            if (!email) {
                this.$root.$refs.Identification.erros.email = 'O campo de e-mail está vazio.'
                return;
            }

            if (!name) {
                this.$root.$refs.Identification.erros.name = 'O campo de Nome está vazio.'
                return;
            }

            if (!regex.test(email)) {
                this.$root.$refs.Identification.erros.email = 'Por favor, insira um e-mail válido.'
                return;
            }         

            try {
                this.$root.$refs.Identification.erros.email = null;

                const identifyCustomer = await this.$store.dispatch('account/identifyCustomerRegister', { email, name, create: true });

                if(!identifyCustomer.success) {
                    console.error('Erro ao identificar o cliente:');
                }
            } catch (error) {
                console.error('Erro ao identificar o cliente:', error);
            }
        });
    },
}
</script>
