import { render, staticRenderFns } from "./insuranceInfo.vue?vue&type=template&id=73ca7478&scoped=true"
import script from "./insuranceInfo.vue?vue&type=script&lang=js"
export * from "./insuranceInfo.vue?vue&type=script&lang=js"
import style0 from "./insuranceInfo.vue?vue&type=style&index=0&id=73ca7478&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73ca7478",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73ca7478')) {
      api.createRecord('73ca7478', component.options)
    } else {
      api.reload('73ca7478', component.options)
    }
    module.hot.accept("./insuranceInfo.vue?vue&type=template&id=73ca7478&scoped=true", function () {
      api.rerender('73ca7478', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/checkoutOnePage/Insurance/insuranceInfo.vue"
export default component.exports