<template> 
    <div class="orderCard" :class="orderIsCancelled && 'orderCard__cancelled'">        
        <div class="orderCard__logoFlag d-flex justify-content-between align-items-center flex-wrap mb-3 gap-2">

            <div class="orderCard__logo">
                <img :src="trip?.trips[0]?.companyLogo?.src" :alt="trip?.trips[0]?.companyLogo?.alt" />
            </div>

            <div class="orderCard__flag">
                <p class="orderCard__flagMsg m-0" v-if="orderIsCancelled">
                    Pedido cancelado
                </p>
            </div>
        </div>

        <div class="orderCard__hourDate">
            <p class="orderCard__date text--isPurple"> 
                <strong class="text--isBold"> {{ trip?.trips[0].tripExtendedDate }}</strong>
            </p>
        </div>

        <div class="orderCard__itinerary d-flex my-3">
            <div class="orderCard__hours d-inline-block mr-2">
                <div class="d-inline-block mr-2">
                    <p class="orderCard__time text--isSmall"> 
                        <strong class="text--isBold">{{ trip?.trips[0].departureTime }}</strong> 
                        {{ trip?.trips[0]?.from }}
                    </p> 
                    <p class="orderCard__time mb-0 text--isSmall"> 
                        <strong class="text--isBold">
                            {{ trip?.trips[0].arrivalTime }}
                        </strong> 
                        {{ trip?.trips[0]?.to }} 
                    </p> 
                </div>                
            </div>
        </div>

        <template v-if="trip.trips[1]">
            <div class="orderCard__logoFlag d-flex justify-content-between align-items-center flex-wrap mb-3 gap-2">

                <div class="orderCard__logo">
                    <img :src="trip?.trips[0]?.companyLogo?.src" :alt="trip?.trips[1]?.companyLogo?.alt" />
                </div>
            </div>

            <div class="orderCard__hourDate">
                <p class="orderCard__date text--isPurple"> 
                    <strong class="text--isBold"> {{ trip?.trips[1].tripExtendedDate }}</strong>
                </p>
            </div>

            <div class="orderCard__itinerary d-flex my-3">
                <div class="orderCard__hours d-inline-block mr-2">
                    <div class="d-inline-block mr-2">
                        <p class="orderCard__time text--isSmall"> 
                            <strong class="text--isBold">{{ trip?.trips[1].departureTime }}</strong> 
                            {{ trip?.trips[1]?.from }}
                        </p> 
                        <p class="orderCard__time mb-0 text--isSmall"> 
                            <strong class="text--isBold">
                                {{ trip?.trips[1].arrivalTime }}
                            </strong> 
                            {{ trip?.trips[1]?.to }} 
                        </p> 
                    </div>                
                </div>
            </div>
        </template>

        <div class="orderCard__info my-4 d-flex flex-wrap">

            <div class="orderCard__infoContent">
                <h6 class="orderCard__heading text--isPurple text--isBold">
                    ID do Pedido
                </h6>
                <p class="orderCard__text my-1">
                    {{ trip?.orderNumber }}
                </p>
            </div>

            <div class="orderCard__infoContent">
                <h6 class="orderCard__heading text--isPurple text--isBold">
                    Data do Pedido
                </h6>
                <p class="orderCard__text my-1">
                    {{ trip?.orderDate}}
                </p>
            </div>

            <div class="orderCard__infoContent">
                <h6 class="orderCard__heading text--isPurple text--isBold">
                    Valor
                </h6>
                <p class="orderCard__text my-1">
                    <strong class="orderCard__priceTotal text--isBold">
                        {{ trip?.priceTotal}}
                    </strong>
                </p>
            </div>

            <div class="orderCard__infoContent">
                <h6 class="orderCard__heading text--isPurple text--isBold">
                    Forma de Pagamento
                </h6>

                <p class="orderCard__text my-1">
                    <template v-if="trip.selectedPaymentInstruments[0].paymentMethod === 'CIELO_CREDIT_CARD'">
                        <span class="d-block mb-1">Cartão de Crédito</span>
                        <span>{{ trip.selectedPaymentInstruments[0].maskedCreditCardNumber }}</span>
                    </template>

                    <span v-if="trip.selectedPaymentInstruments[0].paymentMethod === 'PIX'" class="order__infos">
                       PIX
                    </span>                
                </p>
             
            </div>
            
        </div>

        <div class="orderCard__details mt-4 py-3">
            <div class="orderCard__link text-center mt-2 pt-4">
                <a :href="staticUrl.urlSite+'Order-TicketDetails?orderNumber='+trip?.orderNumber+'&from=orderDetails'" class="orderCard__linkDetails">
                    <strong class="text--isBold orderCard__linkBtn">Ver Detalhes da Viagem</strong>                    
                </a>
            </div>

        </div>

    </div>
  
</template>

<script>
import routes from '../../api/routes';

export default {
    props: {
        orderInformation: {
            type: Object
        }
    },
    data() {
        return {
            trip: this.orderInformation ? this.orderInformation : {},
            staticUrl: routes           
        };
    },    
    computed: {
         orderIsCancelled() {
            const cancelledTrips = this.trip?.trips;
            return cancelledTrips.every(trip => trip.isCancelled === true);            
        }              
    }      
};
</script>