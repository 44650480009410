// Components
import DateCarousel from './components/DateCarousel.vue'
import Modal from './components/ui/Modal.vue'
import Button from './components/ui/ButtonVue.vue'
import RegisterModal from './components/Account/RegisterModal.vue'
import LoginModal from './components/Account/LoginModal.vue'
import ForgetPasswordModal from './components/checkout/Identification/ForgetPasswordModal.vue'
import SharedTicket from './components/ticket/SharedTicket.vue'

// Pages
import Checkout from './pages/Checkout'
import CheckoutOnePage from './pages/CheckoutOnePage'
import TicketDetails from './pages/TicketDetails'
import AccountHistory from './pages/AccountHistory'
import ListTrips from './pages/ListTrips'

export default {
    // Components
    'DateCarousel': DateCarousel,
    'RegisterModal': RegisterModal,
    'LoginModal': LoginModal,
    'ForgetPasswordModal': ForgetPasswordModal,
    'Modal': Modal,
    'ButtonVue': Button,
    'SharedTicket': SharedTicket,

    // Pages
    'Checkout': Checkout,
    'TicketDetails': TicketDetails,
    'CheckoutOnePage': CheckoutOnePage,
    'AccountHistory': AccountHistory,
    'ListTrips': ListTrips
};