<template>
    <div class="container receipt orderPanel__main" ref="ticketDetails">
        <div class="row justify-content-center mb-3">
            <div class="col-sm-8 col-md-12" id="detailsRoot">
                <confirmation-details></confirmation-details>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-8 col-md-12">
                <NavigateButtons />
            </div>
        </div>
        <cancel-modal
            :locator="selectedLocator" 
            :seatNumber="selectedSeatNumber">
        </cancel-modal>
    </div>
</template>

<script>
import ConfirmationDetails from '../components/ticket/ConfirmationDetails.vue';
import * as $ from "jquery";
import NavigateButtons from '../components/ticket/NavigateButtons.vue';
import { mapGetters } from 'vuex';
import CancelModal from '../components/ticket/CancelModal.vue';

export default {
    props: ['data'],
    components: {
        ConfirmationDetails,
        NavigateButtons,
        "cancel-modal": CancelModal
    },
    computed: {
        ...mapGetters('ticket', ['ticketsStatus'])
    },
    data() {
        return {
            trips: {},
            order: {},
            resources: {},
            loadData: false,
            selectedLocator: '',
            selectedSeatNumber: ''
        };
    },
    created() {
        this.$root.$refs.TicketConsult = this;
        this.populateDataInfo()
    },
    mounted(){
        $('.ticket-details-page.loading-skeleton').remove()
    },
    methods: {
        populateDataInfo(){
            var dataParsed = JSON.parse(this.data);
            this.trips = dataParsed.trips;
            this.order = dataParsed.order;
            this.resources = dataParsed.resources;

            const allLineItems = [];
            this.trips.forEach(tripGroup => {
            if (Array.isArray(tripGroup)) {
                tripGroup.forEach(trip => {
                if (trip.lineItems && Array.isArray(trip.lineItems)) {
                    trip.lineItems.forEach(item => {
                    allLineItems.push(item);
                    });
                }
                });
            }
            });

            this.$store.dispatch('tickets/updateTicketsStatus', allLineItems);
        },
    },
};
</script>
