import { render, staticRenderFns } from "./PassengersList.vue?vue&type=template&id=731a5f7e"
import script from "./PassengersList.vue?vue&type=script&lang=js"
export * from "./PassengersList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('731a5f7e')) {
      api.createRecord('731a5f7e', component.options)
    } else {
      api.reload('731a5f7e', component.options)
    }
    module.hot.accept("./PassengersList.vue?vue&type=template&id=731a5f7e", function () {
      api.rerender('731a5f7e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/checkoutOnePage/Passengers/PassengersList.vue"
export default component.exports