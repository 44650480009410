<template>
    <div class="listTripsOrderBy">
        <h3 class="listTripsOrderBy__title">
            Ordenar por:
        </h3>

        <div class="listTripsOrderBy__wrapper">

            <ul class="listTripsOrderBy__lstButtons">
                <li class="listTripsOrderBy__item">
                    <div class="listTripsOrderBy__button">
                        <input type="checkbox" :id="'listTripsOrderBy__tax-'+uuid">
                        <label :for="'listTripsOrderBy__tax-'+uuid">Tarifa menor</label>
                    </div>
                </li>
    
                <li class="listTripsOrderBy__item">
                    <div class="listTripsOrderBy__button">
                        <input type="checkbox" :id="'listTripsOrderBy__time-'+uuid">
                        <label :for="'listTripsOrderBy__time-'+uuid">Duração menor</label>
                    </div>
                </li>
            </ul>

            <div class="listTripsOrderBy__qtdFound">
                <strong class="listTripsOrderBy__qtd">{{ qtdTrips }}</strong>
                <span class="listTripsOrderBy__texFound"> {{ tripFound }}</span>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: {
        qtdTrips: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            uuid: ''
        }
    },
    computed: {
        tripFound() {
            return this.qtdTrips === 1 ? 'Viagem encontrada' : 'Viagens encontradas';
        }
    },
    created(){
        this.uuid = `${Math.random().toString(36).substr(2, 9)}`;
    }

};
</script>
