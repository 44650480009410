function getQueryParam(param) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param) || '';
}
function saveUtmToLocalStorage() {
    var utmData = {
        utm_source: getQueryParam('utm_source') || '',
        utm_medium: getQueryParam('utm_medium') || '',
        utm_campaign: getQueryParam('utm_campaign') || '',
        utm_content: getQueryParam('utm_content') || '',
        utm_term: getQueryParam('utm_term') || '',
        utm_brand: getQueryParam('utm_brand') || '',
        utm_kxconfig: getQueryParam('utm_kxconfig') || '',
        gclid: getQueryParam('gclid') || '',
        institutionSource: getQueryParam('institutionSource') || ''
    };

    localStorage.setItem('utm_source', utmData.utm_source);
    localStorage.setItem('utm_medium', utmData.utm_medium);
    localStorage.setItem('utm_campaign', utmData.utm_campaign);
    localStorage.setItem('utm_content', utmData.utm_content);
    localStorage.setItem('utm_term', utmData.utm_term);
    localStorage.setItem('utm_brand', utmData.utm_brand);
    localStorage.setItem('utm_kxconfig', utmData.utm_kxconfig);
    localStorage.setItem('gclid', utmData.gclid);
    localStorage.setItem('institutionSource', utmData.institutionSource);
}

$(window).on('load', saveUtmToLocalStorage);

$('.search-tickets-btn').on('click', function (e) {
    e.preventDefault();

    saveUtmToLocalStorage();
});

