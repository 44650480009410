import { render, staticRenderFns } from "./CouponSection.vue?vue&type=template&id=e7712156&scoped=true&lang=html"
import script from "./CouponSection.vue?vue&type=script&lang=js"
export * from "./CouponSection.vue?vue&type=script&lang=js"
import style0 from "./CouponSection.vue?vue&type=style&index=0&id=e7712156&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7712156",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e7712156')) {
      api.createRecord('e7712156', component.options)
    } else {
      api.reload('e7712156', component.options)
    }
    module.hot.accept("./CouponSection.vue?vue&type=template&id=e7712156&scoped=true&lang=html", function () {
      api.rerender('e7712156', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/checkoutOnePage/Coupon/CouponSection.vue"
export default component.exports