<template lang="html">
    <div>
        <card-wrapper class="p-0">
            <div>
                <p class="d-flex justify-content-between mb-0">
                    <span class="mob-subtitle--small">
                        {{ totalSeats }}
                        {{ resources.base.seats }}
                    </span>
                    <span class="mob-subtitle--large mob-color--grayTwo">
                        {{ originalSubTotal }}
                    </span>
                </p>
                <p class="d-flex justify-content-between mb-0">
                    <span class="mob-subtitle--small">
                        {{ resources.sidebar.summary.insurance }}
                    </span>
                    <span class="mob-subtitle--large mob-color--grayTwo">
                        {{ insurance }}
                    </span>
                </p>
                <p class="d-flex justify-content-between mb-0">
                    <span class="mob-subtitle--small">
                        {{ resources.sidebar.summary.fee }}
                    </span
                    >
                    <span class="mob-subtitle--large mob-color--grayTwo">
                        {{ serviceTax }}
                    </span>
                </p>

                <div
                    class="row"
                    v-for="(discount, index) in totals.discounts"
                    :key="index"
                    >
                    <p class="col-12 mob-subtitle--small-strong">
                        Cupom de Desconto:
                    </p>
                    <div class="col-6">
                        <p class="mob-subtitle--small mb-0">
                            {{ discount.name }}
                        </p>
                        <div class="d-flex">
                            <span class="mob-text--small-bold appliedCoupon"
                                >{{ resources.base.applied }} -
                            </span>
                            <button
                                type="button"
                                class="mob-text--small-bold removeCoupon"
                                @click="selectCouponToRemove(discount.uuid)"
                            >
                                {{ resources.base.remove }}
                            </button>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <p class="mob-subtitle--large mob-color--grayTwo">
                            {{ discount.formatted }}
                        </p>
                    </div>
                </div>

                <hr />
                <p
                    class="d-flex justify-content-between"
                >
                    <span class="mob-subtitle--medium">{{ resources.sidebar.totalValue }}</span>
                    <span class="mob-heading--large mob-color--grayTwo">
                        {{ totalValue }}
                    </span>
                </p>
            </div>
        </card-wrapper>

        <CouponRemoveModal/>
    </div>
</template>

<script>
import CardWrapper from './CardWrapper'
import { mapGetters, mapState } from 'vuex'
import Modal from '../ui/Modal'
import ButtonVue from '../ui/ButtonVue'
import CouponRemoveModal from './Coupon/CouponRemoveModal.vue';

export default {
    components: {
        'card-wrapper': CardWrapper,
        Modal: Modal,
        'button-vue': ButtonVue,
        CouponRemoveModal,
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            totals: 'totals',
            resources: 'resources',
            totalSeats: 'totalSeats',
            activeInsurance: 'activeInsurance',
            isMobile: 'isMobile',
            loadData: 'loadData',
        }),
        ...mapState('checkoutOnePage', {
            couponToRemove: state => state.couponToRemove,
        }),
        totalValue() {
            if (this.loadData) return ''

            if (
                this.activeInsurance  &&
                this.totals.totalWithInsurance
            ) {
                return this.totals.totalWithInsurance.formatted
            } else {
                return this.totals.totalPrice.formatted
            }
        },
        serviceTax() {
            if (this.loadData) return ''
            return this.totals.serviceTax.formatted
        },
        insurance() {
            if (this.loadData) return ''

            if (this.activeInsurance) {
                return this.totals.insurance.formatted
            } else {
                return '-'
            }
        },
        originalSubTotal() {
            if (this.loadData) return '';
            return this.totals.subTotalPrice.formatted;
        }
    },
    methods: {
        selectCouponToRemove(couponCode) {
            this.couponToRemove = couponCode
            this.$store.commit('checkoutOnePage/setCouponToRemove', couponCode);
            $("#confirmRemoveModal").modal('show');
            $("#coupon-tab").removeClass('collapse');
        }
    },
}
</script>

<style scoped>
.appliedCoupon {
    color: #76cd48;
}

.removeCoupon {
    border: none;
    background: none;
    text-decoration: underline;
}
</style>
