<template>
    <div class="listTrips">

        <div class="container">

            <div class="isDesktop">
                <ListTripsOrderBy :qtd-trips="tripQuantity" />
            </div>

            <div class="listTrips__wrapper">
                <div class="listTrips__filter">
                    componente: filtro />
                </div>

                <div class="listTrips__listCards" v-if="serviceTrip">
                    <ul class="listTrips__items">
                        <li class="listTrips__item" v-for="trip in serviceTrip" :key="trip.arr">
                            <listTripsCard :trip="trip" />
                            <ListTripsBusMap />
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div class="isMobile">
            <ListTripsFilterModal />
        </div>

    </div>
</template>

<script>

import ListTripsFilterModal from '../components/ListTripsFilterModal.vue';
import ListTripsOrderBy from '../components/ListTripsOrderBy.vue';
import ListTripsCard from '../components/ListTripsCard.vue';
import ListTripsBusMap from './ListTripsBusMap.vue';

export default {
    props: {
        data: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            serviceTrip: this.data.lsServicos ? this.data.lsServicos : []
        }
    },

    components: {
        ListTripsOrderBy,
        ListTripsFilterModal,
        ListTripsCard,
        ListTripsBusMap
    },
    computed: {
        tripQuantity() {
            return this.data.lsServicos?.length
        }

    }
};
</script>
