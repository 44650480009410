import { render, staticRenderFns } from "./GuestUser.vue?vue&type=template&id=05a7f49c"
import script from "./GuestUser.vue?vue&type=script&lang=js"
export * from "./GuestUser.vue?vue&type=script&lang=js"
import style0 from "./GuestUser.vue?vue&type=style&index=0&id=05a7f49c&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05a7f49c')) {
      api.createRecord('05a7f49c', component.options)
    } else {
      api.reload('05a7f49c', component.options)
    }
    module.hot.accept("./GuestUser.vue?vue&type=template&id=05a7f49c", function () {
      api.rerender('05a7f49c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/checkout/Identification/GuestUser.vue"
export default component.exports