<template>
    <div class="listTripsCard">

        <div class="listTripsCard__logoFlag">
            <div class="listTripsCard__logo">

            </div>

            <div class="listTripsCard__flag">
                <p class="listTripsCard__flagText">
                    {{ trip.classe }}
                </p>
            </div>

        </div>

        <div class="listTripsCard__trip">
            <div class="listTripsCard__hours">
                <p class="listTripsCard__departureHour"><strong>06:00</strong></p>
                <p class="listTripsCard__departureCity">{{ trip.originName }}</p>
            </div>

            <div class="listTripsCard__time">
                <div class="listTripsCard__busWrapper">
                    <div class="listTripsCard__logoConnection">
                        logo
                    </div>
                    <div class="listTripsCard__busLine">
                        <p class="listTripsCard__tripTime">{{ trip.duration }}</p>
                        <div class="listTripsCard__bus">
                            <i class="icon-bus"></i>
                        </div>
                        <p class="listTripsCarad__connectionCity">
                            São Paulo (Tiete) - SP
                        </p>
                    </div> 
                    <div class="listTripsCard__logoConnection">
                        logo
                    </div>
                </div>
            </div>

            <div class="listTripsCard__hours">
                <p class="listTripsCard__arrivalHour"><strong>08:30</strong></p>
                <p class="listTripsCard__arrivalCity">{{ trip.destinationName }}</p>
            </div>

        </div>

        <div class="listTripsCard__info">

            <div class="listTripsCard__infoWrapper">
                <div class="listTripsCard__seatMobiStatus">

                </div>

                <div class="listTripsCard__price">
                    <p class="listTripsCard__priceValue">
                        {{ trip.preco }}
                    </p>

                </div>
            </div>

        </div>
        
    </div>

</template>

<script>

export default {
    props: {
        trip: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            uuid: ''
        }
    },
    computed: {
        tripFound() {
            return this.qtdTrips === 1 ? 'Viagem encontrada' : 'Viagens encontradas';
        }
    },
    created(){
        this.uuid = `${Math.random().toString(36).substr(2, 9)}`;
    }

};
</script>
