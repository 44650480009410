<template>
    <div class="container">
        <div class="waterMark__istance" v-if="environemntIstance"></div>
        <div class="item__carrossel__header">
            <img :src="`${staticPath}/images/ticketTitle.jpg`" alt="Mobifácil" title="Mobifácil" />
            <img :src="`${staticPath}/images/logo-mobifacil-mobile.png`" alt="Mobifácil" title="Mobifácil" />
        </div>
        <div class="row">
            <div class="col-12">
                <div class="item__carrossel">
                    <div class="product-line-item" v-if="item" :data-product-line-item="item.UUID" :data-ticket-number="item.numeroBilhete">
                        <!-- info -->
                        <div class="ticket-details__container">

                            <div class="details__content">
                                <h3 class="text--isPurple passengerNameClass">{{ item.passengerName }}</h3>

                                <div class="itinerary_items">
                                    <div class="order__hours d-flex align-items-stretch">
                                        <p class="ticket__trip text--isSmall">{{ item.origin }}</p>
                                        <i class="icon-arrow_full"></i>
                                        <p class="ticket__trip text--isSmall">{{ item.destiny }}</p>
                                    </div>
                                </div>
                                <p class="mob-text--large mob-color--primary">Documento: <strong class="bd mob-color--black">{{ item.passengerID }}</strong></p>
                            </div>

                            <div class="product-line-item-details text-center company__group">
                                <div class="item__attributes">

                                    <div class="line-item-attributes date">
                                        <p class="date__label">Data</p>
                                        <strong>{{ section.tripDate }} {{ section.departure.hour }}</strong>
                                    </div>

                                    <div class="line-item-attributes date">
                                        <p class="date__label">Plataforma</p>
                                        <strong>{{ item.platform ? item.platform : '-' }}</strong>
                                    </div>

                                    <div class="line-item-attributes date seat">
                                        <p class="date__label">Assento</p>
                                        <p class="seat__number" v-if="item.seatNumber">{{ item.seatNumber }}</p>
                                    </div>

                                    <div class="line-item-attributes date">
                                        <p class="date__label">Localizador</p>
                                        <strong class="id__label">{{item.locator ? item.locator : '-'}}</strong>
                                    </div>

                                    <div class="line-item-attributes date">
                                        <p class="date__label">Serviço</p>
                                        <strong class="id__label text-left">{{ item.serviceNumber ? item.serviceNumber : '-' }}</strong>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- qr codes -->
                        <div v-if="item.isComporteGroup" class="product-line-item-details text-center qrcode__group">
                            <!-- qr code de liberação de catraca -->
                            <template v-if="item.w2iQRCode">
                                <div class="qrcode__content small">
                                    <img class="qr-code-img qr-code-img--small"
                                    :style="{'opacity': isCancelled ? '0.1' : '1'}"
                                    :src="qrCodeSrc2"
                                    alt="QR code"
                                    title="QR code"
                                    height="150"
                                    width="150"
                                    />
                                    <p class="barcode__text">QR-Code para liberação de catraca</p>
                                </div>
                            </template>

                            <!-- qr code de embarque direto -->
                            <template v-if="item.qrCodeUrl">
                                <div class="qrcode__content">
                                    <img ref="qrCodeImg" class="qr-code-img"
                                        :style="{'opacity': isCancelled ? '0.1' : '1'}"
                                        :src="qrCodeSrc"
                                        alt="QR code"
                                        title="QR code"
                                        height="150"
                                        width="150"
                                    />
                                    <p class="barcode__text">QR para embarque direto</p>
                                    <div class="cancel_flag alert alert-danger" v-if="isCancelled">Viagem Cancelada</div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="qrcode__content">
                                    <div class="cancel_flag alert alert-danger" v-if="isCancelled">Viagem Cancelada</div>
                                    <h6 class="text--isRed text-center" v-else>Atenção passageiro: Passagens para esse trecho devem ser retiradas no guichê</h6>
                                </div>
                            </template>
                        </div>
                        <div v-else-if="item.voucherCompany" style="display: flex; flex-direction: column; justify-content: center; max-width: 300px;">
                            <div class="cancel_flag alert alert-danger" v-if="isCancelled">Viagem Cancelada</div>

                            <div class="alert alert-warning mx-auto text-center" role="alert" v-else>
                                Faça Download do seu Voucher e retire sua passagem na rodoviária, no guichê da viação.
                            </div>
                        </div>
                        <div v-else style="display: flex; flex-direction: column; justify-content: center; max-width: 300px;">
                            <div class="cancel_flag alert alert-danger" v-if="isCancelled">Viagem Cancelada</div>

                            <div class="alert alert-warning mx-auto text-center" role="alert" v-else>
                                Faça o download do seu cartão de embarque
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="item__carrossel__ticket">
            <div class="dot dot-1"></div>
            <h3 class="text--isPurple">{{ item.passengerName }}</h3>
            <p class="seat__number" v-if="item.seatNumber">{{ item.seatNumber }}</p>
            <div class="dot dot-2"></div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode-generator';
import routes from '../../api/routes';
import { mapGetters } from 'vuex';

export default {
    props: ['section', 'item'],
    components: {
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            environemntIstance: ''
        };
    },
    mounted(){
        const divVue = document.getElementById('vueApp');
        this.environemntIstance =  divVue?.dataset.istancetype || '';
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            ticketsStatus: 'ticketsStatus'
        }),
        isCancelled() {
            const found = this.ticketsStatus?.find(li => li.locator === this.item.locator) || [];
            return found ? found.isCancelled : this.item.isCancelled;
        },
        qrCodeSrc() {
            return this.item.qrCodeUrl ? this.generateQRCode(this.item.qrCodeUrl) : '';
        },

        qrCodeSrc2() {
            return this.item.w2iQRCode ? this.generateQRCode(this.item.w2iQRCode) : '';
        },
    },
    methods: {
        generateQRCode(url) {
            let qr = QRCode(0, 'L');
            qr.addData(url);
            qr.make();
            return qr.createDataURL(5, 0);
        },
    },
    watch: {
        'item.qrCodeUrl': function(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.qrCodeImg.src = this.generateQRCode(newVal);
                });
            }
        }
    },
};
</script>

<style scoped lang="scss">
.passengerNameClass{
    font-size: 22px;
    font-weight: 600;
}

.ticketBody {
    .seat__number {
        padding: 8px 12px;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 8px;
    }

    .details__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .itinerary_items .order__hours {
            padding: 0;
        }
        .order__hours {
            display: flex;
            gap: 8px;
        }
    }

    .ticket__trip.text--isSmall,
    i {
        font-weight: bold;
        color: #55368b;
    }

    .item__attributes {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: start;
        align-items: flex-start;
        gap: 24px;
        .line-item-attributes {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p {
                font-weight: bold;
                color: #55368B;
                font-size: 16px;
                text-align: start;
            }
            strong {
                margin: 0;
            }
        }
        .seat__number {
            border: 1px solid #D7D7D7;
        }
    }

    .product-line-item {
        padding-top: 16px;
        padding-right: 100px;
        padding-bottom: 16px;
        padding-left: 20px;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ticket-details__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .details__content {
            max-width: unset;
        }

        .qrcode__content {
            position: relative;
            margin: 0;
            p {
                font-size: 11px;
                font-style: italic;
            }
            .cancel_flag {
                text-transform: uppercase;
                position: absolute;
                width: 120%;
                top: 30%;
                left: -10%;
                font-size: 12px;
                padding: 12px;
                background: #E55D42;
                color: white;
                font-weight: bold;
                font-style: italic;
            }
        }
    }

    .item__carrossel__header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #55368B;
        img {
            width: 70px;
        }
        .ticker--verticalText {
            color: white;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .item__carrossel__ticket {
        background: #55368B;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        h3,
        p {
            color: white;
            text-align: center;
        }
        .dot {
            background: white;
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 100px;

            &.dot-1 {
                top: -12px;
            }
            &.dot-2 {
                bottom: -12px;
            }
        }
    }
}

@media (min-width: 800px) {
    .item__carrossel__header {
        position: absolute;
        border-radius: 0px 10px 10px 0;
        left: 0;
        top: 0;
        height: 300px;
        width: 50px;
        flex-direction: column;
        transform: rotate(180deg);
        img {
            transform: rotate(90deg);
        }
        .ticker--verticalText {
            height: 70px;
            writing-mode: vertical-rl;
        }
    }

    .item__carrossel__ticket {
        height: 300px;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 130px;
        border-left: 4px dashed #F5F5F5;
        border-radius: 0px 10px 10px 0;
        .dot {
            left: -12px;

            &.dot-1 {
                top: -12px;
            }
            &.dot-2 {
                bottom: -12px;
            }
        }
    }
}

@media (max-width: 800px) {
   .ticketBody {

    .item__carrossel__header {
        border-radius: 10px 10px 0px 0px;
        transform: unset;
        height: 60px;
        width: 100%;
        flex-direction: row;
        position: unset;
    }

    .item__attributes {
        .line-item-attributes {
            margin: 0!important;
            &.seat {
                display: none !important;
            }
        }
        gap: 8px;
        grid-template-columns: 1fr;
    }
        .item__carrossel__ticket {
            border-radius: 0px 0px 10px 10px;
            transform: unset;
            position: relative;
            width: 100%;
            height: 70px;
            background: #55368b;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 24px;
            border-top: 4px dashed #F5F5F5;
        }

        .item__carrossel__ticket .dot {
            &.dot-1 {
                left: -12px;
            }
            &.dot-2 {
                top: -12px;
                right: -12px;
            }
        }
    }
}

    .waterMark__istance {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            color: #555555;
            content: "AMBIENTE DE TESTE AMBIENTE DE TESTE AMBIENTE DE TESTE";      
            filter: 
            drop-shadow(5em 2em #555555) 
            drop-shadow(5em 4em #555555) 
            drop-shadow(4em 12em #555555) 
            drop-shadow(-14em -28em #555555);            
            transform: rotate(-26deg);
            font-size: 30px;
            font-weight: 700;
            opacity: 0.2;
            line-height: 8;
            letter-spacing: 2px;
        } 
    }

    @media (min-width: 768px) {
        .waterMark__istance {
               
            &::before {                
                filter: 
                drop-shadow(-17em 5em #555555) 
                drop-shadow(5em 5em #555555) 
                drop-shadow(-6em 4em #555555) 
                drop-shadow(-14em -11em #555555);
                font-size: 60px;            
                line-height: 2;
            }    
        }
    }
</style>
