<template>
    <div class="container">
      <div class="d-flex justify-content-center" v-if="!isCancelled">
        <button-vue
          class="ghost cancelOrder greyButton"
          :label="'Cancelar Pedido ' + $root.$refs.TicketConsult.order.orderNumber"
          data-target="#cancelOrderModal"
          @click="showCancelOrderModal"
        />
        <!-- <cancel-modal></cancel-modal> -->
      </div>
  
      <div class="w-100 d-flex justify-content-between justify-content-md-center px-3 mb-5">
        <button-vue
          class="sm ghost purpleButton justify-content-md-end mr-md-5"
          :class="buttonClass"
          :label="labelCondition 
                  ? (resources.base?.backToTrips || 'Minhas Viagens') 
                  : (resources.base?.backTicketVerify || 'Fazer Nova Consulta')"
          v-if="!isHidden"
          @click="labelCondition ? goHistory() : goConsult()"
        />
        <button-vue
          :class="[!isHidden && isLogged ? 'justify-content-md-start' : 'justify-content-md-center']"
          class="sm ghost purpleButton mb-2 mb-md-0"
          :label="resources.base?.backHome || 'Voltar para Home'"
          @click="goHome"
        />
      </div>
    </div>
  </template>
  
  <script>
  import ButtonVue from '../ui/ButtonVue.vue';
  import CancelModal from './CancelModal.vue';
  import { mapState, mapGetters } from 'vuex';
  import routes from '../../api/routes';
  
  export default {
    components: {
      'cancel-modal': CancelModal,
      'button-vue': ButtonVue,
    },
    props: {
    },
    computed: {
      ...mapState({
        resources: (state) => state.checkout.resources,
      }),
      ...mapGetters('tickets', ['ticketsStatus']),
      isCancelled() {
          if (!this.ticketsStatus || this.ticketsStatus.length === 0) return false;

          return this.ticketsStatus.every(item => item.isCancelled);
      },
      buttonClass() {
        const path = window.location.pathname;

        if (path.includes('orderconfirmation') && sessionStorage.getItem('isLogged') !== 'true') {
          return 'd-none';
        }

        return 'purpleButton';
      },
      isLogged() {
        return sessionStorage.getItem('loginChecked') === 'true' || sessionStorage.getItem('isLogged') === 'true';
      }
    },
    watch: {
      isCancelled(newValue) {
        this.checklabelCondition();
      }
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            labelCondition: false,
            isHidden: false,
        };
    },
    methods: {
      showCancelOrderModal() {
        if (this.$root.$refs.TicketConsult.selectedLocator) {
          this.$root.$refs.TicketConsult.selectedLocator = '';
          this.$root.$refs.TicketConsult.selectedSeatNumber = '';
        }
        $('#cancelOrderModal').modal('show');
      },
      goHome() {
        window.location.href = routes.home.show;
      },
      goConsult() {
        window.location.href = routes.order.consult;
      },
      goHistory() {
        window.location.href = routes.order.history;
      },
      checklabelCondition() {
        const urlParams = new URLSearchParams(window.location.search);
        const userLogged = sessionStorage.getItem('loginChecked') === 'true' || sessionStorage.getItem('isLogged') === 'true';
        const path = window.location.pathname;

        // Verificar se está na consulta de bilhete (prioridade máxima)
        if (urlParams.has('from') && urlParams.get('from') === 'consultTicket') {
            this.labelCondition = false; // Sempre "resources.base?.backTicketVerify"
            this.isHidden = false;

            return;
        }

        // Verificar se está cancelado
        if (this.$root.$refs.TicketConsult.order.isCancelled) {
            if (userLogged) {
                this.labelCondition = true; // Usuário logado, mostra "resources.base?.backToTrips"
            } else {
                this.labelCondition = false; // Usuário não logado, mostra "resources.base?.backTicketVerify"
            }
            this.isHidden = false;
        } 
        // Página de confirmação
        else if (path.includes('orderconfirmation')) {
            this.labelCondition = userLogged; // "resources.base?.backToTrips" se logado
            this.isHidden = !userLogged; // Oculta o botão se não estiver logado
        } 
        // Minhas viagens
        else if (urlParams.has('from') && urlParams.get('from') === 'orderDetails' && userLogged) {
            this.labelCondition = true; // "resources.base?.backToTrips"
        } 
        // Qualquer outro caso
        else {
            this.labelCondition = false; // "resources.base?.backTicketVerify"
        }
      }
    },
    mounted(){
        this.checklabelCondition();
    }
  };
  </script>
  
  <style scoped lang="scss">
  .purpleButton {
    color: #55368B !important;
    font-size: 14px;
  }
  .greyButton{
    color: #555555 !important;
    font-size: 14px;
  }

  @media (max-width: 800px){
    .greyButton, .purpleButton{
        font-size: 12px;
    }
  }

  </style>
  