<template lang="html">
        <div>
            <modal modalId="cancelOrderModal" size="lg" @close="showModal=false">
            <template v-slot:title class="center">
                <h2 class="modal-title" id="">{{ cancelTitleMessage }}</h2>
            </template>
            <template v-slot:body>
                <div class="d-flex align-items-center">
                    <span class="title">{{ cancelBodyMessage }} </span>
                    <div v-if="seatNumber" class="d-flex ml-3 align-items-center seatBorder">
                        <img :src="`${staticPath}/images/seatIcon.png`" alt="Numero da Poltrona" width="21" height="26" class="mr-2"> {{ seatNumber }}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <button-vue
                        :label="seatNumber ? 'Cancelar Poltrona' : 'Cancelar Pedido'"
                        className="outlined sm"
                        @click="cancelOrder"
                        :isLoad="load"
                    />
                    <button-vue
                        label="Voltar"
                        className="solid sm"
                        data-dismiss="modal"
                    />
                </div>
            </template>
        </modal>
        <error-cancel-modal></error-cancel-modal>
        <success-cancel-modal></success-cancel-modal>
        </div>
</template>

<script>
import Modal from '../ui/Modal.vue';
import routes from '../../api/routes';
import ButtonVue from '../ui/ButtonVue.vue';

import { mapGetters } from 'vuex';
import ErrorCancelModal from './ErrorCancelModal.vue';
import SuccessCancelModal from './SuccessCancelModal.vue';

    export default {
        components: {
            'modal': Modal,
            'button-vue': ButtonVue,
            'error-cancel-modal': ErrorCancelModal,
            'success-cancel-modal': SuccessCancelModal,
        },
        props: {
            locator: {
            type: String,
            required: false
            },
            seatNumber: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                staticPath: routes.urlStatic,
                load: false
            };
        },
        computed: {
            ...mapGetters('account', ['email']),
            cancelTitleMessage() {
                const params = this.getUrlParams(window.location.href);
                const orderNumber = params.orderNumber || this.$root.$refs.TicketConsult.order.orderNumber;

                return this.seatNumber 
                    ? "Cancelar Poltrona?" 
                    : `Cancelar pedido ${orderNumber}?`;
            },
            cancelBodyMessage() {
                return this.seatNumber 
                    ? `Você tem certeza que deseja cancelar essa poltrona?` 
                    : "Você tem certeza que deseja cancelar todas as poltronas desse pedido?";
            }
        },
        methods: {
            async cancelOrder(){
                this.load = true;
                const params = this.getUrlParams(window.location.href)
                const data = {
                    email: params.email,
                    orderNumber: params.orderNumber || params.ID || this.$root.$refs.TicketConsult.order.orderNumber,
                    cancelLineItemID: this.locator
                }
                const result = await this.$store.dispatch('tickets/cancelOrder', data);
                this.load = false;
                $('#cancelOrderModal').modal('hide');
            },
            getUrlParams(url) {
                const params = {};
                const urlParts = url.split('?');
                if (urlParts.length > 1) {
                    const queryString = urlParts[1];
                    const paramPairs = queryString.split('&');

                    paramPairs.forEach(pair => {
                        const [key, value] = pair.split('=');
                        params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
                    });
                }
                return params;
            }
        },
        watch: {}
    }
</script>