<template>
    <div class="ListTripsFilterModal">

        <transition name="fade">

            <div v-if="showModal" class="ltf__modal">
                <div class="ltf__modalContainer">
                    <button class="ltf__modalClose" @click="closeModal"><i class="ltf__modalClose--icon icon-close-modal"></i></button>
                    <div class="container">
                        <div v-if="action === 'filter'">
                            <h3 class="ltf__modalTittle">Filtrar</h3>
                        </div>

                        <div v-if="action === 'sort'">
                            <h3 class="ltf__modalTittle">Ordernar</h3>
                            <ListTripsOrderBy />
                        </div>

                    </div>
                </div>
                <div class="ltf__modalBg"></div>
            </div>
        </transition>

        <ul class="ListTripsFilterModal__buttons">
            <li class="ListTripsFilterModal__item">
                <button class="ListTripsFilterModal__button" data-action="filter" @click="openModal($event)">
                    <i class="ListTripsFilterModal__icon icon-filter"></i>
                    <span>Filtrar</span>
                </button>
            </li>

            <li class="ListTripsFilterModal__item">
                <button class="ListTripsFilterModal__button" data-action="sort" @click="openModal($event)">
                    <i class="ListTripsFilterModal__icon icon__sortDown icon-sort-down"></i>
                    <span>Ordernar</span>
                </button>
            </li>
        </ul>
      
    </div>

</template>

<script>
import ListTripsOrderBy from './ListTripsOrderBy.vue';

export default {
    props: {
        qtdTrips: {
            type: Number,
            default: 0
        }
    },
    components: { ListTripsOrderBy },
    data() {
        return {
            showModal: false,
            action: ''
        }
    },  
    computed: {
        tripFound() {
            return this.qtdTrips === 1 ? 'Viagem encontrada' : 'Viagens encontradas';
        }
    },
    methods: {
        openModal(el) {
            this.showModal = true;

            this.action = el.currentTarget.dataset.action;
        },
        closeModal() {
            this.showModal = false;
        }
    }
};
</script>

<style lang="scss" scoped>

    .fade-enter-active, 
    .fade-leave-active {
        transition: opacity .1s;      
    }

    .fade-leave-to {
        transition: opacity .2s;      
    }

    .fade-enter, 
    .fade-leave-to {
        opacity: 0;
    }

    .ltf__modalClose {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .ltf__modalClose--icon {
        color: #979797;
        font-size: 20px;
    }

    .ltf__modalTittle {
        color: #333;
        font-weight: 700;
        font-size: 18px;
    }

    .ltf__modalContainer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 24px 8px;
        border-radius: 10px 10px 0 0;
        background: #FFF;
        z-index: 90;      
    }

    .ltf__modalBg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
        pointer-events: none;
        z-index: 80;
    }

</style>
