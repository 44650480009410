import { render, staticRenderFns } from "./TicketCard.vue?vue&type=template&id=5a3e9060&scoped=true"
import script from "./TicketCard.vue?vue&type=script&lang=js"
export * from "./TicketCard.vue?vue&type=script&lang=js"
import style0 from "./TicketCard.vue?vue&type=style&index=0&id=5a3e9060&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3e9060",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/maur32/Documents/comporte.sfcc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a3e9060')) {
      api.createRecord('5a3e9060', component.options)
    } else {
      api.reload('5a3e9060', component.options)
    }
    module.hot.accept("./TicketCard.vue?vue&type=template&id=5a3e9060&scoped=true", function () {
      api.rerender('5a3e9060', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_comporte_base/cartridge/client/default/js/Vue/components/ticket/TicketCard.vue"
export default component.exports